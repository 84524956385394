import React from 'react';
import type { FC } from 'react';
import { Link, graphql } from 'gatsby';
import { Layout } from '../components/layout';
// eslint-disable-next-line import/no-unresolved
import { PalliativeCarePageQuery } from 'graphql-types';
import { SEO } from '../components/seo';

interface PageProps {
  data: PalliativeCarePageQuery;
}

const Page: FC<PageProps> = ({ data }) => (
  <Layout>
    <SEO
      title="緩和ケア"
      description="腫瘍をわずらった犬と猫に適切な緩和ケアを。緩和ケアは診断初期から始める大切な治療の一環です"
      keywords="緩和ケア"
    />
    <article id="clinical-examination">
      <h2>緩和ケア - Palliative care -</h2>
      <div className="skew-border"></div>
      <section>
        <p>
          「外科療法」「化学療法」「放射線療法」はがん治療の3本柱と言われています。私はここに「緩和療法」が4本目の柱であると確信しております。腫瘍による疼痛そして腫瘍の治療に伴う苦痛からの解放は、動物の生活の質だけではなく飼い主様の心労と苦痛を軽減することにも繋がります。私は医療の分野で急速に発展している緩和ケアに関して「日本緩和医療学会」に所属し、日本緩和医療学会が提唱するe-learningも修了しました。動物医療においても、動物に寄り添った専門的な緩和ケアが必要です。
        </p>
      </section>
      <section>
        <h3>シニアケア - Senior care -</h3>
        <p>
          腫瘍疾患をわずらう動物の多くは高齢であり、腫瘍以外にも心身に様々な老齢性の変化が起こります。褥瘡・失禁・便失禁・起立困難などの身体トラブルについても是非ご相談ください。
        </p>
      </section>
    </article>
  </Layout>
);
export default Page;

export const query = graphql`
  query PalliativeCarePage {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
